import styled from '@emotion/styled';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import React from 'react';

import Input from '../input';
import SelectBase from './select-base';

const SelectStyled = styled(SelectBase)`
  &.error {
    .react-select__control {
      border: 1px solid red !important;
    }
  }
`;

const SelectInput = ({
  label,
  name,
  className,
  mensagemSuporte,
  value,
  disabled,
  inputAdornment,
  endAdornment,
  errors = [],
  ...props
}) => {
  if (disabled) {
    return (
      <Input
        name={name}
        label={label}
        value={value?.label}
        disabled
        shrink={value ? true : undefined}
        errors={errors}
        {...props}
      />
    );
  }

  return (
    <FormGroup>
      {label && <InputLabel sx={styles.label}>{label}</InputLabel>}
      <FormControl fullWidth className={'select-input'} size="small">
        <SelectStyled
          id="input"
          className={`${value === 0 || value ? 'withValue' : ''}${
            errors[name] ? 'error' : ''
          }`}
          value={value || ''}
          notched={value ? true : undefined}
          endAdornment={endAdornment}
          error={!!errors[name]}
          {...props}
        />
        {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
      </FormControl>
    </FormGroup>
  );
};

const styles = {
  label: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#fff',
    marginBottom: '4px',
  },
};

export default React.memo(SelectInput);
